#weatherSvg {
  display: block;
  flex: 4;
  width: 100%;
}

#weatherSvg,
#weatherSvg svg {
  overflow: visible;
}

.line {
  fill: none;
  stroke-width: 1.5px;
}

.gridY line {
  opacity: 0.25;
  stroke-dasharray: 5;
}

.axisY .tick line {
  display: none;
}
