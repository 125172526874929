#app {
  background: #fbf9f9;
  display: flex;
  flex-flow: row wrap;
  font-weight: 200;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 1200px;
  min-height: 100vh;
}

@media (max-width: 1030px) {
  #app {
    margin: 1rem;
  }
}
@media (max-width: 700px) {
  #app {
    display: block;
  }
}
