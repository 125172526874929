header h1 {
  color: #000;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0;
}

.emerson {
  font-weight: 400;
}

a {
  font-weight: 400;
}
