footer {
  bottom: 1rem;
  box-sizing: border-box;
  color: #333;
  left: 0;
  position: absolute;
  width: 100%;
}

@media (max-width: 1000px) {
  footer {
    bottom: 0;
    position: relative;
  }
}

footer > div {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 8px;
}

footer a {
  display: flex;
  text-decoration: none;
}
