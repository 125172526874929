.data-selection-wrapper {
  flex: 1;
  margin-right: 1rem;
}

.data-selection {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
}

@media only screen and (min-width: 1100px) {
  .data-selection {
    justify-content: center;
  }
}

/* safari */
.data-selection::-webkit-scrollbar {
  display: none;
}

.dataset {
  background: none;
  border: 0px solid #333;
  box-sizing: border-box;
  display: block;
  margin-bottom: 0.25rem;
  min-width: 200px;
  padding: 0.5rem;
  text-align: left;
  width: fit-content;
}

.dataset:focus {
  box-sizing: border-box;
  outline: 0;
  text-decoration: underline;
}

.dataset--selected {
  background-color: rgb(40, 40, 40);
  color: white;
}

.data-description {
  background-color: rgb(204, 204, 204);
  margin-top: 8px;
  min-height: 60px;
  padding: 16px 0;
  text-align: center;
}

.data-description span {
  flex: 1 0 auto;
}

.data-selection-header {
  border-bottom: 0.5px solid lightgray;
  font-size: 1.1rem;
  font-weight: 300;
  margin-bottom: 0 0 0.5rem 0.25rem;
}

@media (max-width: 700px) {
  .data-selection-header {
    text-align: center;
  }
  .data-selection-wrapper {
    margin: 0 auto;
    max-width: 280px;
  }
  .dataset {
    margin: 0 auto;
    text-align: center;
  }
}
